import { CarbonSDK, CarbonSigner, CarbonWalletGenericOpts, Keplr, KeplrAccount, KeplrWindow } from 'carbon-js-sdk'
import { History } from 'history'
import { Action } from 'redux'

import { StaticLinks } from 'js/constants/externalLinks'
import { FeatureType } from 'js/constants/notification'
import { logout } from 'js/state/modules/account/actions'
import { setCarbonSDK, setSubPage, setWalletToggle } from 'js/state/modules/app/actions'
import { Page } from 'js/state/modules/app/types'
import { customToast } from 'js/utils/notifications'

import { runTimedTask } from './misc'

export const detectKeplrWallet = () => {
  return !!(window as KeplrWindow).keplr
}

export const getKeplr = async (sdk: CarbonSDK) => {
  const hasKeplr = detectKeplrWallet()
  if (!hasKeplr) return null

  const keplr = (window as KeplrWindow).keplr! as Keplr
  const chainInfo = await KeplrAccount.getChainInfo(sdk)
  await keplr.experimentalSuggestChain(chainInfo)
  await keplr.enable(chainInfo.chainId)

  return keplr
}

export const connectKeplr = async (sdk: CarbonSDK, walletOpts: CarbonWalletGenericOpts): Promise<CarbonSDK | null> => {
  const connectedSdk = await runTimedTask(async (signal) => {
    let keplr = await getKeplr(sdk)
    if (!keplr) {
      await new Promise((resolve) => setTimeout(resolve, 500)) // delay 500ms for keplr injection
      keplr = await getKeplr(sdk)
    }
    if (!keplr) return null

    const chainInfo = await KeplrAccount.getChainInfo(sdk)
    const account = await keplr.getKey(chainInfo.chainId)
    const keplrSigner: CarbonSigner = account.isNanoLedger
      ? KeplrAccount.createKeplrSignerAmino(keplr, chainInfo, account)
      : KeplrAccount.createKeplrSigner(keplr, chainInfo, account)
    const pubKeyBase64 = Buffer.from(account.pubKey).toString('base64')

    if (signal.aborted) return null

    await sdk.connectWithSigner(keplrSigner, pubKeyBase64, {
      ...walletOpts,
      providerAgent: 'keplr-extension',
    })
    return sdk
  }, {
    abortMessage: 'Uh-oh, it seems the wallet connection has timed out. Please ensure that your Keplr wallet is unlocked.',
    durationInMs: 10000,
  })

  return connectedSdk
}

export const isSignerErr = (error: Error) => {
  return error.message.includes('Failed to retrieve account from signer')
}

export const isSignerMismatchErr = (error: Error) => {
  return error.message.includes('Signer mismatch')
}

export const isSignerDataInvalidErr = (error: Error) => {
  return error.message.includes('Unexpected characters')
}

export const isKeplrCheck = (sdk: CarbonSDK | undefined) => {
  return sdk && (sdk.wallet?.providerAgent === 'keplr-extension')
}

export const isKeplrError = (error: Error, sdk: CarbonSDK | undefined) => {
  const isKeplr = isKeplrCheck(sdk)
  return isKeplr && (
    isSignerErr(error)
    || isSignerMismatchErr(error)
    || isSignerDataInvalidErr(error)
  )
}

export const handleDataInvalidErr = (
  sdk: CarbonSDK | undefined,
  history: History,
  dispatch: (action: Action) => unknown,
  showToast: boolean | undefined,
) => {
  if (showToast) {
    customToast.error({
      title: 'Error',
      message: 'Connecting Ledger through Keplr is not supported. Please reconnect with Ledger directly and try again.',
      featureType: FeatureType.WALLET,
      action: {
        label: 'Reconnect with Ledger',
        cta: () => switchToLedger(sdk, history, dispatch),
      },
    })
  }

  return new Error('Connecting Ledger through Keplr is not supported. Please reconnect with Ledger directly and try again.')
}

export const switchToLedger = (sdk: CarbonSDK | undefined, history: History, dispatch: (action: Action) => unknown) => {
  if (!sdk?.wallet) return

  try {
    dispatch(logout())
    const disconnectedSDK: CarbonSDK = sdk.disconnect()
    dispatch(setCarbonSDK(disconnectedSDK))

    dispatch(setSubPage(Page.Ledger))
    dispatch(setWalletToggle(true))
  } catch (err) {
    console.error(err)
  }
}

export const handleKeplrSignerErrors = (error: Error, sdk: CarbonSDK | undefined, toast: boolean = false) => {
  if (isSignerErr(error)) {
    if (toast) {
      customToast.error({
        title: 'Error',
        message: 'Connected to wrong network on Keplr. Follow the guide to connect to Carbon.',
        featureType: FeatureType.WALLET,
        link: {
          href: StaticLinks.DemexDocs.Wallet.SwitchNet,
          label: 'Switch Networks',
          target: '_blank',
        },
      })
    }
  }

  if (isSignerMismatchErr(error)) {
    if (toast) {
      customToast.error({
        title: 'Oops!',
        message: `Connected to another account on Keplr. Switch to the following account and try again: ${sdk?.wallet?.bech32Address ?? ''}`,
        featureType: FeatureType.WALLET,
        link: {
          href: StaticLinks.DemexDocs.Wallet.SwitchNet,
          label: 'Switch Networks',
          target: '_blank',
        },
      })
    }
    return new Error(`Connected to another account on Keplr. Switch to the following account and try again: ${sdk?.wallet?.bech32Address ?? ''}`)
  }

  return error
}

export const handleKeplrErrors = (
  error: Error,
  sdk: CarbonSDK | undefined,
  history: History,
  dispatch: (action: Action) => unknown,
  showToast: boolean | undefined,
) => {
  console.error(error)
  if (isSignerErr(error) || isSignerMismatchErr(error)) {
    return handleKeplrSignerErrors(error, sdk, showToast)
  }
  return handleDataInvalidErr(sdk, history, dispatch, showToast)
}

export const getKeplrInstance = () => {
  const keplr = (window as KeplrWindow).keplr
  if (typeof keplr === 'undefined') {
    return undefined
  }
  return keplr
}
