import BigNumber from 'bignumber.js'
import { CarbonSDK, Insights } from 'carbon-js-sdk'
import dayjs from 'dayjs'

import { ReactComponent as Creek } from 'assets/trading_competition/Creek.svg'
import { ReactComponent as Ocean } from 'assets/trading_competition/Ocean.svg'
import { ReactComponent as Pond } from 'assets/trading_competition/Pond.svg'
import { ReactComponent as Raindrop } from 'assets/trading_competition/Raindrop.svg'
import { ReactComponent as River } from 'assets/trading_competition/River.svg'
import { ReactComponent as Sea } from 'assets/trading_competition/Sea.svg'

import { StyleUtils } from '../utils/styles'

// add hard-coded time to ensure the entire duration of the competition is captured
// have to change again before competition is launched

export enum Iteration {
  Past = 'past',
  Current = 'current',
  Upcoming = 'upcoming'
}

// Mobile Promotion Details
export const mobilePromoStartTime = '2023-08-29T09:00:00Z'
export const mobilePromoEndTime = '2023-09-04T09:00:00Z'

export const competitionId = 'mmLeapMobilePromoAUG'

export function isMobilePromoOngoing() {
  const currentUTCDayjs = dayjs.utc()
  return currentUTCDayjs.isBetween(
    mobilePromoStartTime,
    mobilePromoEndTime,
    'milliseconds',
    '[]',
  )
}

// Swap Promotion
export const swapPromoStartTime = '2023-09-29T09:00:00Z'
export const swapPromoEndTime = '2023-10-02T09:00:00Z'

// King of The Hill
export const kingOfTheHillStartTime = '2023-11-15T00:00:00Z'
export const kingOfTheHillEndTime = '2023-12-15T00:00:00Z'

// Current competition details
export const startTime = '2023-08-15T09:00:00Z'
export const endTime = '2023-09-15T10:00:00Z'
export const endTimeVol1 = '2023-08-30T09:00:00Z'
export const startTimeVol2 = '2023-08-30T10:00:00Z'
export const endTimeBooster = '2023-09-15T09:00:00Z'

export function getStartDate(competition?: Insights.CompetitionItem): dayjs.Dayjs | undefined {
  if (!competition) return undefined
  const startDate = dayjs.utc(competition.start)
  return startDate
}

export function getEndDate(competition?: Insights.CompetitionItem): dayjs.Dayjs | undefined {
  if (!competition) return undefined
  const endDate = dayjs.utc(competition.end)
  return endDate
}

export function isCompetitionOngoing(competition?: Insights.CompetitionItem) {
  const startDate = getStartDate(competition)
  const endDate = getEndDate(competition)
  if (!startDate || !endDate) return false

  const currentUTCDayjs = dayjs.utc()
  return currentUTCDayjs.isBetween(
    startDate,
    endDate,
    'milliseconds',
    '[]',
  )
}

export enum Competition {
  PNL = 'competition-pnl',
  VolumeRankings = 'competition-volume-rankings',
  TradingLeague = 'competition-league',
  Lottery = 'competition-lottery',
}

export const competitionMarkets: { [key in CarbonSDK.Network]: string } = {
  [CarbonSDK.Network.MainNet]: 'cmkt/141',
  [CarbonSDK.Network.TestNet]: 'BTC_PERP.USDC',
  [CarbonSDK.Network.DevNet]: 'BTC_PERP.USDC',
  [CarbonSDK.Network.LocalHost]: 'BTC_PERP.USDC',
}

export interface RankTier {
  title: string
  threshold: BigNumber
  rewards: BigNumber
  noRaffle?: boolean
  svg?: SVGComponent
  indicatorValue?: number
  prizeText?: string
  thresholdText?: string
  borderColor?: string
}

export const tierArr: RankTier[] = [{
  title: '-',
  threshold: new BigNumber(0),
  rewards: new BigNumber(0),
  noRaffle: true,
  borderColor: StyleUtils.primaryBranding,
}, {
  title: 'Raindrop',
  threshold: new BigNumber(5000),
  rewards: new BigNumber(0),
  svg: Raindrop,
  indicatorValue: 15,
  prizeText: '$1,000 Raffle (Split amongst 200 winners)',
  thresholdText: '≥$5,000',
  borderColor: StyleUtils.primaryBranding,
}, {
  title: 'Creek',
  threshold: new BigNumber(45000),
  rewards: new BigNumber(5),
  svg: Creek,
  indicatorValue: 30,
  borderColor: StyleUtils.accentError,
}, {
  title: 'Pond',
  threshold: new BigNumber(150000),
  rewards: new BigNumber(20),
  svg: Pond,
  indicatorValue: 45,
  borderColor: StyleUtils.accentWarning,
}, {
  title: 'River',
  threshold: new BigNumber(600000),
  rewards: new BigNumber(100),
  svg: River,
  indicatorValue: 60,
  borderColor: StyleUtils.accentSuccess,
}, {
  title: 'Sea',
  threshold: new BigNumber(1500000),
  rewards: new BigNumber(300),
  svg: Sea,
  indicatorValue: 75,
  borderColor: StyleUtils.primaryTextGradient,
}, {
  title: 'Ocean',
  threshold: new BigNumber(3000000),
  rewards: new BigNumber(1000),
  svg: Ocean,
  indicatorValue: 90,
  borderColor: StyleUtils.accentWarning,
}]

// Leaderboard page params
export const defaultPageNumber = 1
export const RESULTS_PER_PAGE = 10

// Carbon Credits initial reward pool
export const INITIAL_REWARD_POOL = new BigNumber(400000)

// Carbon Credits reward cap
export const REWARD_POOL_CAP = new BigNumber(1600000)

// Carbon Credits MerkleClaim Contracts

export const MERKLE_CLAIM_CONTRACTS = {
  [CarbonSDK.Network.MainNet]: '0x6611Fb9d41B2C2a8763fcaE7eB420bAFb18C478D',
  [CarbonSDK.Network.TestNet]: '0xCF5F71Dc76D33577E67A5aeCC4259dB4ba6f1dfb',
  [CarbonSDK.Network.DevNet]: '0x34e88B3486eb7D41b98B499a34Dc88698C82cd80',
  [CarbonSDK.Network.LocalHost]: '0xc6308E2886E9dfb057EB10162869f9c0d3fCBEd2',
}

export const CARBON_CREDITS_EVM_WORKAROUND = true
export const FEES_REBATE_PERCENTAGE = 10
export const FETCH_REWARDS_DELAY = 3000

export interface RewardsPerSeason {
  season: number,
  rewards: BigNumber,
  rewardsUsd?: BigNumber,
}

export interface Crew {
  name: string
  requiredLentAssets: string[]
  requiredLentAssetQuantity: number
}

export interface StepIconItem {
  label: string
  icon: SVGComponent
  fillType?: 'fill' | 'stroke'
  iconClass?: string
}

export const LOSS_PROTECTION_REWARD_POOL = new BigNumber(2000)
export const LOSS_PROTECTION_COMPETITION_ID = 'lossprotectionsep2024'
export const LOSS_PROTECTION_START_UNIX = 1725955200 // 10 Sep 2024, 8AM UTC
export const LOSS_PROTECTION_END_UNIX = 1728547200 // 10 Oct 2024 8AM UTC
