
import { StatsigProvider } from "@statsig/react-bindings"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"

import { EvtCategory, useEventTrackers } from "js/hooks"
import { getIsPwaUpdated } from "js/state/modules/app/selectors"
import { getStatsigClient } from "js/state/modules/statsig/selectors"
import { darkTheme } from "js/theme"

import AppLoading from "./AppLoading"
import ThemedApp from "./ThemedApp"

const AppProvider = () => {
  const eventTrackers = useEventTrackers()

  const statsigClient = useSelector(getStatsigClient)
  const isPwaUpdated = useSelector(getIsPwaUpdated)

  useEffect(() => {
    if (!isPwaUpdated) return
    const timer = setTimeout(() => {
      eventTrackers.sendEvent(EvtCategory.Misc, 'refresh_update_shown')
      window.location.reload()
    }, 1500)

    return () => clearTimeout(timer)
  }, [eventTrackers, isPwaUpdated])

  if (isPwaUpdated) return (
    <AppLoading />
  )

  if (statsigClient) return (
    <StatsigProvider client={statsigClient}>
    <ThemedApp theme={darkTheme} />
    </StatsigProvider>
  )

  return (
    <ThemedApp theme={darkTheme} />
  )
}

export default AppProvider
