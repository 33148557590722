import { Network } from 'carbon-js-sdk/lib/constant' // eslint-disable-line import/no-unresolved
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'

import { AlertsApis } from 'js/constants/alerts'
import { ApiErrorResponse, ApiResponse, HttpMethod } from 'js/constants/api'

import { sendAlertsApiRequest } from './jwt'
import { Category, getSectionFromCategory, Id, Section } from './user'

export enum Priority {
  Sticky = 'sticky',
  Normal = 'normal'
}

export enum Status {
  New = 'new',
  Read = 'read',
  Deleted = 'deleted',
}

export interface UpdateInboxStatus {
  ids: number[]
  status: Status
  address: string
}

export type UpdateInboxStatusRequest = UpdateInboxStatus[]

export interface UpdateInboxStatusResponse {
  entries: Inbox[]
}

export interface Cta {
  text?: string
  redirect?: string
}

export interface Inbox {
  id: number
  address: string
  category: Category
  title: string
  body: string
  cta: Cta | null 
  priority: Priority | null
  status: Status
  createdAt: string
  updatedAt: string
}

export interface SortedInboxNotifs {
  notifications: Inbox[]
  counts: {
    [Status.New]: number
  }
}


export type InboxSectionsNotifs = Record<Section, SortedInboxNotifs>


export const InboxWebsockets: Record<Network, string> = {
  [Network.MainNet]: 'wss://ws-notification-api.dem.exchange',
  [Network.TestNet]: 'wss://test-ws-notification-api.dem.exchange',
  [Network.DevNet]: 'wss://dev-ws-notification-api.dem.exchange',
  [Network.LocalHost]: 'ws://localhost:9790',
}


export const updateInboxStatus = async (network: Network, accessToken: string, body: UpdateInboxStatusRequest): Promise<ApiResponse<UpdateInboxStatusResponse> | ApiErrorResponse> => {
  const url = `${AlertsApis[network].external}/inbox/update`
  return await sendAlertsApiRequest<UpdateInboxStatusResponse>(HttpMethod.POST, url, body, accessToken)
}

export const groupNotifications = (inbox: Map<Id, Inbox> | null): InboxSectionsNotifs => {
  const groups: InboxSectionsNotifs = Object.values(Section).reduce((acc, section) => {
    acc[section] = { notifications: [], counts: { [Status.New]: 0 } }
    return acc
  }, {} as InboxSectionsNotifs)

  if (!inbox) return groups
  return Array.from(inbox.values()).reduce((acc, notif) => {
    const { category, status } = notif
    const section: Section | undefined = getSectionFromCategory(category)
    if (!section) return acc
    const isNew = status === Status.New
    if (isNew) acc[section].counts[Status.New]++
    acc[section].notifications.push(notif)
    return acc
  }, groups)
}


export const updateInbox = (initialState: Map<Id, Inbox> | null, updates: Inbox[]) => {
  const clonedUpdates = cloneDeep(updates)
  const inbox = updateStatus(initialState, clonedUpdates)
  return sortBasedOnTimestamp(inbox)
}


const updateStatus = (initialState: Map<Id, Inbox> | null, updates: Inbox[]): Map<Id, Inbox> => {
  const inbox: Map<Id, Inbox> = initialState ? initialState : new Map()

  updates.forEach(update => {
    if (update.status === Status.Deleted) {
      inbox.delete(update.id)
    } else {
      inbox.set(update.id, update)
    }
  })

  return inbox
}

const sortBasedOnTimestamp = (inbox: Map<Id, Inbox>): Map<Id, Inbox> => {
  return new Map([...inbox.entries()].sort(([, notifA], [, notifB]) => {
    const { createdAt: timestampA } = notifA!
    const { createdAt: timestampB } = notifB!
    return dayjs(timestampB).unix() - dayjs(timestampA).unix()
  }))
}

