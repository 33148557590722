import { makeStyles, Theme, ThemeProvider, useMediaQuery } from '@material-ui/core'
import clsx from 'clsx'
import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router'
import { Route, Switch } from 'react-router-dom'

import { StaticLinks } from 'js/constants/externalLinks'
import Paths from 'js/constants/paths'
import { getDebugMode, getDemexConfig } from 'js/state/modules/app/selectors'
import { darkTheme, lightTheme } from 'js/theme/ThemeV2'
import { HIDE_FLUO_STAKING, IS_ENV_ALPHA, IS_ENV_DEVELOP, IS_STRATEGIES_MAINNET } from 'js/utils/environment'
import { StyleUtils, useCommonStyles } from 'js/utils/styles'

import BottomAppBar from './BottomAppBar'
import DesktopHeaderBar from './DesktopHeaderBar'
import PageLoadFallback from './PageLoadFallback'
import { AccountFallback, CDPFallback, CommonFormFallback, GLPFallback, HomeFallback, ManageVaultsFallback, MarketsListFallback, PoolsFallback, PromotionFallback, StakeFallback, SwapFallback } from './PageLoadFallbacks'
import TestEvmTransaction from './TestEvmTransaction'
import GlobalBackdrop from './components/GlobalBackdrop'
import HighlightFocus from './components/HighlightFocus'
import LoginParamListener from './components/LoginParamListener'
import Notifications from './components/Notifications'
import PageContainer from './components/PageContainer'
import ReferralParamListener from './components/ReferralParamListener'

import RenderGuard from '../Common/RenderGuard'
import TextButton from '../Common/TextButton'
import "js/components/Toast/Toast" // extra import to initialize toast handler

const Error404 = React.lazy(() => import('js/components/Errors/Error404'))
const Maintenance = React.lazy(() => import('js/components/Errors/Maintenance'))
const MobileNetworkConnectAlert = React.lazy(() => import('./MobileNetworkConnectAlert'))
const MobileHeaderBar = React.lazy(() => import('./MobileHeaderBar'))
const MobileNavBar = React.lazy(() => import('./MobileNavBar'))
const WalletChangeListener = React.lazy(() => import('./WalletChangeListener'))
const TopLevelAlerts = React.lazy(() => import('./TopLevelAlerts'))
const ViewOnlyBanner = React.lazy(() => import('./components/ViewOnlyBanner'))
const WelcomeOffer = React.lazy(() => import('../WelcomeOffer/WelcomeOffer'))
const ActivateAccountDialog = React.lazy(() => import('./components/ActivateAccountDialog'))
const CrossSellingDialog = React.lazy(() => import('./components/CrossSellingWidget/CrossSellingDialog'))
const ConfettiContainer = React.lazy(() => import('./components/ConfettiContainer'))
const MenuSlider = React.lazy(() => import('./components/MenuSlider'))
const MobilePromoDialog = React.lazy(() => import('./components/MobilePromoDialog/MobilePromoDialog'))
const ModeSelectionDialog = React.lazy(() => import('./components/ModeSelectionDialog'))
const TokenContractButtonPopup = React.lazy(() => import('./components/TokenContractButtonPopup'))
const SignlessSettingsDialog = React.lazy(() => import('./components/SignlessSettingsDialogs/SignlessSettingsDialog'))
const AppTitle = React.lazy(() => import('../Common/AppTitle'))
const SagaRunner = React.lazy(() => import('./SagaRunner'))

const Home = React.lazy(() => import('../Home/home'))
const Account = React.lazy(() => import('js/components/Account/Account'))
const Stake = React.lazy(() => import('../Stake/New/Stake'))
const Swap = React.lazy(() => import('../Exchange/Swap/Swap'))
const RequestSigningModal = React.lazy(() => import('js/components/App/RequestSigningModal'))
const CDP = React.lazy(() => import('js/components/Borrow/CDP'))
const Pools = React.lazy(() => import('js/components/LiquidityPool/Pools'))
const CreateMarket = React.lazy(() => import('../Markets/CreateMarket'))
const ImportToken = React.lazy(() => import('../Markets/ImportToken'))
const ListMarkets = React.lazy(() => import('../Markets/ListMarkets'))
const PromotionRouter = React.lazy(() => import('../PromotionHub/PromotionRouter'))
const CompetitionRouter = React.lazy(() => import('../PromotionHub/TradingCompetition/CompetitionRouter'))
const Strategies = React.lazy(() => import('../Strategies/Strategies'))
const Vaults = React.lazy(() => import('../Vaults/Vaults'))
const FluoDashboard = React.lazy(() => import('../Vaults/FluoDashboard/FluoDashboard'))
const LaunchVaults = React.lazy(() => import('../Vaults/Prelaunch/LaunchVaults'))
const GLPWrapper = React.lazy(() => import('../GLPWrapper/GLPWrapper'))
const Alerts = React.lazy(() => import('js/components/Alerts'))
const VerifyAccountDialog = React.lazy(() => import('../Account/UserProfile/components/VerifyAccountDialog'))
const CrossSellingWidget = React.lazy(() => import('js/components/App/components/CrossSellingWidget'))
const TypeformWidget = React.lazy(() => import('./components/TypeformWidget'))
interface Props {
  theme?: Theme
}

const ThemedApp: React.FC<Props> = (props: Props) => {
  const { theme } = props
  const classes = useStyles()
  const commonClasses = useCommonStyles()
  const demexWebConfig = useSelector(getDemexConfig)
  const debugMode = useSelector(getDebugMode)
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const renderMaintenance = !debugMode && !!demexWebConfig.maintenance
  const { typeformWidgetConfig } = demexWebConfig

  return (
    <ThemeProvider theme={theme}>
      <ReferralParamListener />
      <LoginParamListener />

      <Suspense>
        <WalletChangeListener />
        <SagaRunner />
      </Suspense>

      <Suspense>
        <AppTitle />
      </Suspense>

      <Suspense>
        <ViewOnlyBanner />
      </Suspense>

      <main className={classes.app}>
        <GlobalBackdrop />
        <DesktopHeaderBar isMaintenance={renderMaintenance} />
        <HighlightFocus />

        <Suspense>
          <VerifyAccountDialog />
        </Suspense>
        <Suspense>
          <MenuSlider />
        </Suspense>

        {isMobile && (
          <div className={classes.connectionAlertWrapper}>
            <Suspense>
              {!renderMaintenance && <MobileNetworkConnectAlert />}
            </Suspense>
          </div>
        )}
        <Suspense>
          <MobileHeaderBar isMaintenance={renderMaintenance} />
          <TopLevelAlerts />
          <MobilePromoDialog />
          <ActivateAccountDialog />
          <TokenContractButtonPopup />
          <ConfettiContainer />
          <ModeSelectionDialog />
          <SignlessSettingsDialog />
          <CrossSellingDialog />
        </Suspense>

        {/* Show maintenance page only (i.e. under maintenance) */}
        <RenderGuard renderIf={renderMaintenance}>
          <Switch>
            <Route path={Paths.Home}>
              <Suspense fallback={<PageLoadFallback />}>
                <Maintenance
                  title={demexWebConfig.maintenance?.title ?? 'Sorry, we\'re down for maintenance right now.'}
                  message={(
                    <React.Fragment>
                      {demexWebConfig.maintenance?.message}
                      <br />
                      We apologize for the inconvenience. Rest assured all funds are safu. You can still contact us on <TextButton href={StaticLinks.Socials.Telegram} target="_blank" label="Telegram" isInline /> or <TextButton href={StaticLinks.Socials.Discord} target="_blank" label="Discord" isInline />. We&apos;ll be back up soon!
                    </React.Fragment>
                  )}
                />
              </Suspense>
            </Route>
            <Redirect to={Paths.Home} />
          </Switch>
        </RenderGuard>

        {/* Show everything as per normal (i.e. not under maintenance) */}
        <RenderGuard renderIf={!renderMaintenance}>
          <Switch>
            <Route path={Paths.Account.Home}>
              <PageContainer>
                <Suspense fallback={<AccountFallback />}>
                  <Account />
                </Suspense>
              </PageContainer>
            </Route>
            <Route path={Paths.Swap.Home}>
              <PageContainer>
                <Suspense fallback={<SwapFallback />}>
                  <Swap />
                </Suspense>
              </PageContainer>
            </Route>
            <Route path={Paths.Stake.List}>
              <PageContainer isDisplayFlex={false}>
                <Suspense fallback={<StakeFallback />}>
                  <Stake />
                </Suspense>
              </PageContainer>
            </Route>
            <Route path={Paths.Pools.List}>
              <PageContainer isDisplayFlex={false}>
                <Suspense fallback={<PoolsFallback />}>
                  <Pools />
                </Suspense>
              </PageContainer>
            </Route>
            <Route path={Paths.Competition.Main}>
              <Suspense fallback={<PageLoadFallback />}>
                <CompetitionRouter />
              </Suspense>
            </Route>
            <Route path={Paths.Nitron.Main}>
              <Suspense fallback={<CDPFallback />}>
                <CDP />
              </Suspense>
            </Route>
            <Route path={Paths.Strategy.GLPWrapper}>
              <Suspense fallback={<GLPFallback />}>
                <GLPWrapper />
              </Suspense>
              <div className={clsx(commonClasses.flex1, classes.fullBackground)} />
            </Route>
            <Route path={Paths.Vaults.Manage}>
              <Suspense fallback={<ManageVaultsFallback />}>
                <Vaults />
              </Suspense>
            </Route>
            {!HIDE_FLUO_STAKING && (
              <Route path={Paths.FluoDashboard.Main}>
                <PageContainer isDisplayFlex={false}>
                  <Suspense fallback={<PageLoadFallback />}>
                    <FluoDashboard />
                  </Suspense>
                </PageContainer>
              </Route>
            )}
            {!HIDE_FLUO_STAKING && (
              <Route path={Paths.LaunchVaults.Main}>
                <Suspense fallback={<PageLoadFallback />}>
                  <LaunchVaults />
                </Suspense>
              </Route>
            )}
            {!IS_STRATEGIES_MAINNET && (
              <Route path={Paths.Strategy.Main}>
                <Suspense fallback={<PageLoadFallback />}>
                  <Strategies />
                </Suspense>
                <div className={clsx(classes.fullBackground, commonClasses.flex1)} />
              </Route>
            )}
            <Route exact path={Paths.Markets.List}>
              <Suspense fallback={<MarketsListFallback />}>
                <ListMarkets />
              </Suspense>
            </Route>
            <Route exact path={Paths.Markets.Create}>
              <Suspense fallback={<CommonFormFallback />}>
                <CreateMarket />
              </Suspense>
            </Route>
            <Route exact path={Paths.Markets.Import}>
              <Suspense fallback={<CommonFormFallback />}>
                <ImportToken />
              </Suspense>
            </Route>
            <Route path={Paths.Rewards.Main}>
              <Suspense fallback={<PromotionFallback />}>
                <PromotionRouter />
              </Suspense>
            </Route>
            <Route path={Paths.WelcomeOffer}>
              <Suspense fallback={<PageLoadFallback />}>
                <WelcomeOffer />
              </Suspense>
            </Route>
            <Route path={Paths.Errors.Maintenance}>
              <Suspense fallback={<PageLoadFallback />}>
                <Maintenance />
              </Suspense>
            </Route>
            <Route path={Paths.Errors.Error404}>
              <Suspense fallback={<PageLoadFallback />}>
                <Error404 />
              </Suspense>
            </Route>
            <Route path={Paths.Alerts.Main}>
              <PageContainer>
                <Suspense fallback={<PageLoadFallback />}>
                  <Alerts />
                </Suspense>
              </PageContainer>
            </Route>

            {(IS_ENV_ALPHA || IS_ENV_DEVELOP) && (
              <Route path="/test-evm-transactions">
                <Suspense fallback={<PageLoadFallback />}>
                  <TestEvmTransaction />
                </Suspense>
              </Route>
            )}

            {/* Redirect to Trading League page when user enters Marathon URL */}
            {/* TODO: Remove when not required anymore */}
            <Redirect exact path={Paths.Competition.MegaMarathon} to={Paths.Competition.Main} />
            <Redirect exact path={Paths.Stake.LegacyStake} to={Paths.Stake.List} />
            <Redirect exact from={Paths.LegacyPaths.EarnAThon} to={Paths.Rewards.EarnAThon} />
            <Redirect from={Paths.LegacyPaths.MobilePromotion} to={Paths.Rewards.MobilePromotion} />
            <Redirect exact from={Paths.LegacyPaths.Liquidations.Main} to={Paths.Nitron.Liquidations} />
            <Redirect from={Paths.LegacyPaths.Liquidations.RiskyDetails} to={Paths.Nitron.LiquidationRiskyDetails} />
            <Redirect from={Paths.LegacyPaths.Liquidations.Repay} to={Paths.Nitron.LiquidationRepay} />
            <Redirect exact from={Paths.Competition.Promotion} to={Paths.Rewards.FlashPromotion} />
            <Redirect exact from={Paths.LegacyPaths.FlashPromotion} to={Paths.Rewards.FlashPromotion} />
            <Redirect exact from={Paths.LegacyPaths.PromotionHub.Main} to={Paths.Rewards.Main} />
            <Redirect exact from={Paths.LegacyPaths.PromotionHub.RewardsHistory} to={Paths.Rewards.RewardsHistory} />
            <Redirect exact from={Paths.LegacyPaths.PromotionHub.Referrals} to={Paths.Rewards.Referrals} />
            <Redirect exact from={Paths.LegacyPaths.PromotionHub.ReferralsEarnings} to={Paths.Rewards.ReferralsEarnings} />
            <Redirect exact from={Paths.LegacyPaths.PromotionHub.ReferralsSettings} to={Paths.Rewards.ReferralsSettings} />
            <Redirect exact from={Paths.LegacyPaths.PromotionHub.SwapPromotion} to={Paths.Rewards.SwapPromotion} />
            <Redirect exact from={Paths.LegacyPaths.PromotionHub.FlashPromotion} to={Paths.Rewards.FlashPromotion} />
            <Redirect exact from={Paths.LegacyPaths.PromotionHub.MobilePromotion} to={Paths.Rewards.MobilePromotion} />
            <Redirect exact from={Paths.LegacyPaths.PromotionHub.EarnAThon} to={Paths.Rewards.EarnAThon} />
            <Redirect exact from={Paths.LegacyPaths.PromotionHub.KingOfTheHill} to={Paths.Rewards.KingOfTheHill} />
            <Redirect exact from={Paths.LegacyPaths.PromotionHub.PerpsTradingChallenge} to={Paths.Rewards.PerpsTradingChallenge} />


            <Route path={Paths.Home}>
              <PageContainer>
                <Suspense fallback={<HomeFallback />}>
                  <Home />
                </Suspense>
              </PageContainer>
            </Route >
            <Redirect to={Paths.Home} />
          </Switch >
        </RenderGuard >
        <BottomAppBar />
        <Suspense>
          <MobileNavBar />
        </Suspense>
      </main>

      <Suspense>
        <RequestSigningModal />
      </Suspense>

      <Suspense>
        {typeformWidgetConfig && typeformWidgetConfig.length !== 0 && typeformWidgetConfig.map((config) => (
          <TypeformWidget key={config.surveyLink} message={config.message} surveyLink={config.surveyLink} endTime={config.endTime} pages={config.pages} />
        ))}
        <CrossSellingWidget />
      </Suspense>

      <Notifications />
    </ThemeProvider >
  )
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    app: {
      minHeight: 'calc(100vh - 28px)',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      backgroundColor: theme.palette.type === 'dark' ? darkTheme.palette.background.base : lightTheme.palette.background.base,
      paddingBottom: '28px',
      [theme.breakpoints.down('sm')]: {
        paddingBottom: 0,
        minHeight: '100vh',
      }
    },
    link: {
      cursor: 'pointer',
    },
    fullBackground: {
      background: theme.palette.background.base,
    },
    connectionAlertWrapper: {
      backgroundColor: theme.palette.type === 'dark' ? darkTheme.palette.background.base : lightTheme.palette.background.base,
    },
    orangeStrong: {
      fontWeight: 700,
      background: StyleUtils.accentWarning,
      backgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      WebkitBackgroundClip: 'text',
    },
  }
})

export default ThemedApp
