import { FormControlLabel, makeStyles, Theme, Typography } from '@material-ui/core'
import { Insights } from 'carbon-js-sdk'
import clsx from 'clsx'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { TypographyLabel } from 'js/components/Common'
import AlertBanner from 'js/components/Common/AlertBanner'
import LearnMoreLink from 'js/components/Common/LearnMoreLink'
import TextButton from 'js/components/Common/TextButton'
import V2Switch from 'js/components/Common/V2Switch'
import RawError from 'js/components/Toast/RawError'
import { StaticLinks } from 'js/constants/externalLinks'
import { setAutoSelectNode, setFormNode, showNodeInfoForm } from 'js/state/modules/app/actions'
import { getAutoSelectNode } from 'js/state/modules/app/selectors'
import { SimpleMap } from 'js/utils'

import { ReactComponent as AddIcon } from 'assets/AddGradient.svg'

import NodeTable from './NodeTable'

import NetworkToggle from '../NetworkToggle'

interface Props {
  handleSelectNode: (node: any) => void
  recommendedNode: Insights.NodeItem
  selectingNode: any
  connectionAlert?: SimpleMap<React.ReactNode>
  setFirstLoad: React.Dispatch<React.SetStateAction<boolean>>
}

const NodeTablePage: React.FC<Props> = (props: Props) => {
  const { handleSelectNode, recommendedNode, selectingNode, setFirstLoad, connectionAlert } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const autoSelectNode = useSelector(getAutoSelectNode)

  const handleAutoSelect = () => {
    dispatch(setAutoSelectNode(!autoSelectNode))
    setFirstLoad(true)
  }

  const handleAddCustomNode = () => {
    dispatch(showNodeInfoForm())
    dispatch(setFormNode(undefined))
  }

  return (
    <React.Fragment>
      {connectionAlert && connectionAlert?.alertTitle && (
        <AlertBanner
          variant="error"
          title={connectionAlert.alertTitle as string}
          message={(
            <React.Fragment>
              <TypographyLabel color="textPrimary" className={clsx(classes.subtitle, { 'withAlertText': !!connectionAlert.alertText })}>{connectionAlert.alertSubtitle}</TypographyLabel>
              <TypographyLabel color="textSecondary" className={clsx(classes.text, { withAlertErrMesg: !!connectionAlert.errMessage })}>{connectionAlert.alertText}</TypographyLabel>
              {connectionAlert.errMessage && (
                <RawError error={connectionAlert.errMessage as string} />
              )}
            </React.Fragment>
          )}
          allowCollapse={false}
        />
      )}
      <div className={classes.networkMenu}>
        <div>
          <div className={classes.dropdownHeader}>
            <NetworkToggle setFirstLoad={setFirstLoad} />
            <FormControlLabel
              className={classes.switch}
              control={(
                <V2Switch
                  color="primary"
                  size="medium"
                  onChange={handleAutoSelect}
                  checked={autoSelectNode}
                />
              )}
              label={(
                <Typography className={classes.switchLabel}>
                  Auto-select
                </Typography>
              )}
              labelPlacement="start"
            />
          </div>
          <div className={classes.tableContainer}>
            <NodeTable handleSelectNode={handleSelectNode} recommendedNode={recommendedNode} selectingNode={selectingNode} />
            <TextButton
              startIcon={AddIcon}
              label="Add Custom Node"
              onClick={handleAddCustomNode}
              boxClass={classes.addButton}
            />
          </div>
        </div>
        <div className={classes.dropdownFooter}>
          <LearnMoreLink
            label="Learn more about Public Community Nodes here."
            className={classes.link}
            href={StaticLinks.DemexDocs.Community.Nodes}
            svgClass={classes.learnMoreIcon}
          />
        </div>
      </div>
    </React.Fragment>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  subtitle: {
    ...theme.typography.body4,
    lineHeight: '13px',
    '&.withAlertText': {
      marginBottom: theme.spacing(0.5),
    }
  },
  text: {
    ...theme.typography.body4,
    lineHeight: '13px',
    '& ul': {
      margin: 0,
      paddingLeft: '1em',
    },
    '&.withAlertErrMesg': {
      marginBottom: theme.spacing(0.5),
    }
  },
  networkMenu: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(1, 0, 1.5),
    borderRadius: '0.25rem',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  tableContainer: {
    padding: 0,
  },
  dropdownHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(0.75),
      paddingTop: theme.spacing(0.75),
      borderTop: `1px solid ${theme.palette.divider}`,
      marginLeft: theme.spacing(2.5),
      marginRight: theme.spacing(2.5),
    },
  },
  switch: {
    justifyContent: 'flex-end',
    marginLeft: 0,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
  },
  switchLabel: {
    ...theme.typography.body3,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(0.5),
    whiteSpace: 'nowrap',
  },
  addButton: {
    padding: theme.spacing(1.5, 2.5),
    whiteSpace: 'nowrap',
    '& svg': {
      maxWidth: '0.875rem',
      height: '0.875rem',
      '&.start': {
        marginRight: theme.spacing(1.25),
      },
    },
    '& p': {
      ...theme.typography.body3,
      fontWeight: 700,
    },
  },
  dropdownFooter: {
    ...theme.typography.body4,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.secondary,
    borderTop: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(1.5, 2.25, 0, 2.25),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      padding: theme.spacing(2, 2.25, 0, 2.25),
    },
  },
  link: {
    '& p': {
      ...theme.typography.body4,
      fontWeight: 700,
    },
  },
  learnMoreIcon: {
    height: '8px',
    width: '8px',
    paddingTop: '1px',
    [theme.breakpoints.down('xs')]: {
      width: '11px',
    },
  },
  gradientText: {
    ...theme.typography.body4,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
}))

export default NodeTablePage
