import { toBech32Address } from '@zilliqa-js/zilliqa'
import { CarbonSDK, Hydrogen } from 'carbon-js-sdk'
import { N3Address, NEOAddress } from 'carbon-js-sdk/lib/util/address'
import { BlockchainV2 } from 'carbon-js-sdk/lib/util/blockchain'
import { stripHexPrefix } from 'carbon-js-sdk/lib/util/generic'

import { IS_ENV_STAGING, IS_ENV_DEVELOP } from 'js/utils/environment'
import { reverseStr } from 'js/utils/strings'
import { SimpleMap } from 'js/utils/types'

import { ReactComponent as ExternalLinkIcon } from 'assets/ExternalLink.svg'

export const StaticLinks = {
  JoinValidator: 'https://docs.carbon.network/introduction/getting-started/carbon-node-setup/validator-guide',
  SwitcheoTokenArticle: 'https://blog.switcheo.network/a-brave-new-chapter/',
  CarbonNetwork: 'https://carbon.network/',
  TermsConditions: 'https://guide.dem.exchange/technical/terms-and-conditions',
  Blog: 'https://blog.switcheo.com/',
  Api: 'https://docs.carbon.network/',
  ContactUs: 'https://support.switcheo.network/en/',
  Ideas: 'https://ideas.dem.exchange/',
  Home: 'https://dem.exchange/',
  PromotionHub: {
    Promotions: 'https://content.carbon.network/contentful/master/demexPromotion',
    WinnersList: 'https://guide.dem.exchange/earn/promotions/winners-list',
  },
  ThirdPartyLogin: 'https://web3auth.io',
  Socials: {
    Facebook: 'https://www.facebook.com/SwitcheoExchange',
    LinkedIn: 'https://www.linkedin.com/company/switcheo',
    SwitcheoTwitter: 'https://x.com/SwitcheoNetwork',
    DemexTwitter: 'https://x.com/demexchange',
    Reddit: 'https://www.reddit.com/r/Switcheo/',
    Telegram: 'https://t.me/demex_community',
    TelegramANN: 'https://t.me/demex_ann',
    Discord: 'https://discord.com/invite/SPh62Hf',
    Github: 'https://github.com/switcheo',
    Bar: 'https://ideas.dem.exchange/',
  },
  ExternalBlockchains: {
    Eth: 'https://etherscan.io/tx/',
    EthRopsten: 'https://ropsten.etherscan.io/tx/',
    Zil: 'https://viewblock.io/zilliqa/tx/',
    Neo: 'https://neo2.neotube.io/transaction/',
    Neo3Mainnet: 'https://neotube.io/transaction/',
    Neo3Testnet: 'https://testnet.neotube.io/transaction/',
    Bsc: 'https://bscscan.com/tx/',
    BscTestnet: 'https://testnet.bscscan.com/tx/',
    Mantle: 'https://mantlescan.xyz/',
    MantleTestnet: 'https://sepolia.mantlescan.xyz/',
  },
  DemexDocs: {
    Home: 'https://guide.dem.exchange/',
    DemexApp: 'https://guide.dem.exchange/welcome/getting-started/demex-app',
    Referrals: 'https://guide.dem.exchange/earn/referrals',
    LiquidityPools: {
      Main: 'https://guide.dem.exchange/earn/liquidity-pools',
      CreatePool: 'https://guide.dem.exchange/welcome/getting-started/how-to-start-trading-on-demex/permissionless-liquidity-pool-creation',
      Instructions: 'https://guide.dem.exchange/products/what-is-liquidity-pooling/providing-liquidity-on-demex',
      Faqs: 'https://guide.dem.exchange/products/what-is-liquidity-pooling/faqs',
    },
    Trading: {
      Spot: 'https://guide.dem.exchange/trade/trading-modes/trading-futures-on-demex/features',
      Perpetuals: 'https://guide.dem.exchange/trade/futures',
      Futures: 'https://guide.dem.exchange/trade/futures',
      LiteMode: 'https://guide.dem.exchange/trade/futures/user-guide/lite-mode',
      Leverage: 'https://guide.dem.exchange/trade/futures/trading-mode/trading-futures-on-demex#setting-market-leverage',
      MaintenanceMarginFormula: 'https://guide.dem.exchange/trade/futures/contract-specifications#maintenance-margin',
      InitialMarginFormula: 'https://guide.dem.exchange/trade/futures/contract-specifications#initial-margin',
      IndexPrice: 'https://guide.dem.exchange/trade/futures/glossary#index-price',
      MarkPrice: 'https://guide.dem.exchange/trade/futures/glossary#mark-price',
      FundingRate: 'https://guide.dem.exchange/trade/futures/glossary#funding-rate',
      TPSL: 'https://guide.dem.exchange/trade/trading-modes/trading-futures-on-demex/features/trigger-stop-orders',
    },
    Fees: 'https://guide.dem.exchange/getting-started/fees',
    TradingFees: 'https://guide.dem.exchange/welcome/fees/trading-fees',
    NetworkFees: 'https://guide.dem.exchange/welcome/fees/network-fees',
    SignlessTransaction: 'https://guide.dem.exchange/welcome/getting-started/signless-transactions',
    FlexibleNetworkFees: 'https://guide.dem.exchange/trade/fees/network-fees#flexible-network-fees',
    Wallet: {
      WalletConnection: 'https://guide.dem.exchange/welcome/getting-started/connect-wallet',
      SwitchNet: 'https://guide.dem.exchange/welcome/getting-started/connect-wallet/keplr/reset-connection',
      MetaMaskGuide: 'https://guide.dem.exchange/welcome/getting-started/wallet-connection/metamask/user-guide',
      MetaMaskMigrationGuide: 'https://guide.dem.exchange/welcome/getting-started/wallet-connection/metamask/user-guide/migration',
      ActivateAccount: 'https://guide.dem.exchange/welcome/getting-started/wallet-connection/metamask/user-guide/activate-account',
    },
    ExternalRegions: 'https://guide.dem.exchange/technical/terms-and-conditions#restricted-territory',
    Competition: {
      Current: {
        Main: 'https://guide.dem.exchange/community/promotions/live/demex-trading-league-iii',
      },
      Upcoming: {
        Main: 'https://guide.dem.exchange/community/promotions/live/demex-trading-league-iii',
        TopGainers: 'https://guide.dem.exchange/earn/promotions/live/demex-usd100-000-mega-marathon/demex-trading-league-vol-1',
        Round6: 'https://guide.dem.exchange/earn/promotions/live/demex-trading-league-round-6',
        Scrt: 'https://guide.dem.exchange/earn/events/upcoming/scrt-trading-league',
        ScrtTC: 'https://guide.dem.exchange/earn/events/upcoming/scrt-trading-league#terms-and-conditions',
        ARCH: 'https://guide.dem.exchange/earn/events/ongoing/archway-trading-league',
        ARCHFAQ: 'https://guide.dem.exchange/earn/events/ongoing/archway-trading-league#faqs',
        ARCHTC: 'https://guide.dem.exchange/earn/events/ongoing/archway-trading-league#terms-and-conditions',
        CosmosLearnMore: 'https://guide.dem.exchange/earn/events/ongoing/cosmos-trading-competition#cosmos-trading-competition',
        CosmosFAQ: 'https://guide.dem.exchange/earn/events/ongoing/cosmos-trading-competition#faqs',
        CosmosTC: 'https://guide.dem.exchange/earn/events/ongoing/cosmos-trading-competition#terms-and-conditions',
        CosmosPrizeDistribution: 'https://guide.dem.exchange/earn/events/ongoing/cosmos-trading-competition#prize-distribution'
      },
      ContactUs: 'https://linktr.ee/demexchange'
    },
    CarbonCredits: {
      Main: 'https://guide.dem.exchange/trade/fees/carbon-credits',
    },
    DemexPoints: {
      Intro: 'https://guide.dem.exchange/demex-points/introduction',
      FAQ: 'https://guide.dem.exchange/demex-points/faqs',
    },
    LossProtection: {
      Main: 'https://guide.dem.exchange/demex-rewards/loss-protection',
    },
    Borrows: { // learn more links on tooltip
      Main: 'https://guide.dem.exchange/products/nitron',
      Lend: 'https://guide.dem.exchange/guides/how-to-use-nitron/lend-assets',
      Withdraw: 'https://guide.dem.exchange/guides/how-to-use-nitron/withdraw-assets',
      Borrow: 'https://guide.dem.exchange/guides/how-to-use-nitron/borrow-assets',
      Mint: 'https://guide.dem.exchange/guides/how-to-use-nitron/mint-usdusc',
      Return: 'https://guide.dem.exchange/guides/how-to-use-nitron/return-assets',
      Edit: 'https://guide.dem.exchange/guides/how-to-use-nitron/edit-collateral',
      HealthFactor: 'https://guide.dem.exchange/nitron/how-to-use-nitron/glossary#health-factor',
      EMode: 'https://guide.dem.exchange/nitron/introduction/efficiency-mode',
      IsolationMode: 'https://guide.dem.exchange/nitron/glossary#isolation-mode',
    },
    Liquidations: {
      Main: 'https://guide.dem.exchange/nitron/nitron-liquidations',
    },
    GroupTokens: {
      Main: 'https://guide.dem.exchange/welcome/getting-started/how-to-start-trading-on-demex/grouped-tokens',
      CarbonGroupedTokenGuide: 'https://guide.dem.exchange/welcome/demex-specific-tokens/carbon-grouped-tokens/usdg',
    },
    GlpCompounder: 'https://guide.dem.exchange/earn/vaults/glp-compounder',
    GlpStrategiesArticle: 'https://blog.switcheo.com/earn-the-highest-yield-on-arbitrum-glp-with-demex/',
    CGlpAuditReport: 'https://guide.dem.exchange/earn/vaults/glp-compounder/cglp-audit-report',
    Derivatives: {
      MarkPrice: 'https://guide.dem.exchange/trade/derivatives-trading/glossary',
      FundingRate: 'https://guide.dem.exchange/trade/derivatives-trading/contract-specifications',
    },
    HowToStart: {
      ImportToken: 'https://guide.dem.exchange/welcome/getting-started/how-to-start-trading-on-demex/permissionless-token-import',
      CreateMarket: 'https://guide.dem.exchange/welcome/getting-started/how-to-start-trading-on-demex/permissionless-market-creation',
      Deposit: 'https://guide.dem.exchange/welcome/getting-started/how-to-start-trading-on-demex/deposit/carbon-native-token',
    },
    Community: {
      Nodes: 'https://guide.dem.exchange/community/public-community-nodes',
      Referrals: 'https://guide.dem.exchange/community/referrals',
    },
  },
  CarbonDocs: {
    MarkPrice: 'https://guide.carbon.network/module-architecture/carbon-core/derivatives-module/pricing-engine#mark-price',
    IndexPrice: 'https://guide.carbon.network/module-architecture/carbon-core/derivatives-module/oracles#index-price',
    LastPriceMarking: 'https://guide.carbon.network/carbon-modules/carbon-core/derivatives-module/pricing-engine#last-price-protected',
    FairPriceMarking: 'https://guide.carbon.network/carbon-modules/carbon-core/derivatives-module/pricing-engine#fair-price-marking',
    LiquidationEngine: 'https://guide.carbon.network/carbon-modules/carbon-core/derivatives-module/liquidation-engine',
    Tokenomics: 'https://guide.carbon.network/carbon-tokens/carbon-token-swth/tokenomics',
  },
  DemexBlog: {
    Main: 'https://blog.dem.exchange',
    SCRTPartnership: 'https://blog.dem.exchange/demex-partners-with-secret-network/',
  },
  Repos: {
    CarbonJsSDK: 'https://github.com/Switcheo/carbon-js-sdk',
    CarbonTestnets: 'https://github.com/Switcheo/carbon-testnets',
  },
  BlockChainPaths: {
    [CarbonSDK.Network.MainNet]: {
      Ethereum: 'https://etherscan.io:path',
      'Binance Smart Chain': 'https://bscscan.com:path',
      Neo: 'https://neo2.neotube.io:path',
      Neo3: 'https://neotube.io:path',
      Zilliqa: 'https://viewblock.io/zilliqa:path',
      Polynetwork: 'https://explorer.poly.network:path',
      Osmosis: 'https://www.mintscan.io/osmosis:path',
      'Terra Classic': 'https://finder.terra.money/columbus-5:path',
      'Cosmos Hub': 'https://www.mintscan.io/cosmos:path',
      Juno: 'https://www.mintscan.io/juno:path',
      Evmos: 'https://www.mintscan.io/evmos:path',
      Axelar: 'https://axelarscan.io:path',
      Stride: 'https://www.mintscan.io/stride:path',
      Kujira: 'https://finder.kujira.app/kaiyo-1:path',
      Terra: 'https://finder.terra.money/mainnet:path',
      Comdex: 'https://www.mintscan.io/comdex:path',
      Stafihub: 'https://www.mintscan.io/stafi:path',
      'Persistence Core': 'https://www.mintscan.io/persistence:path',
      Stargaze: 'https://www.mintscan.io/stargaze:path',
      Arbitrum: 'https://arbiscan.io:path',
      Polygon: 'https://polygonscan.com:path',
      OKC: 'https://www.oklink.com/okc:path',
      Canto: 'https://cosmos-explorers.neobase.one/canto:path',
      Sommelier: 'https://www.mintscan.io/sommelier:path',
      Decentr: 'https://atomscan.com/decentr:path',
      'IRIS Hub': 'https://www.mintscan.io/iris:path',
      Noble: 'https://www.mintscan.io/noble:path',
      DYDX: 'https://www.mintscan.io/dydx:path',
      Archway: 'https://www.mintscan.io/archway:path',
      Mantle: 'https://explorer.mantle.xyz/:path',
      OP: 'https://optimistic.etherscan.io/:path',
    },
    [CarbonSDK.Network.DevNet]: {
      Ethereum: 'https://rinkeby.etherscan.io:path',
      'Binance Smart Chain': 'https://testnet.bscscan.com:path',
      'BSC testnet': 'https://testnet.bscscan.com:path',
      Arbitrum: 'https://goerli.arbiscan.io:path',
      Polygon: 'https://mumbai.polygonscan.com:path',
      OKC: 'https://www.oklink.com/okc-test:path',
      Neo: '',
      Neo3: 'https://neo3.testnet.neotube.io/:path',
      Zilliqa: 'https://viewblock.io/zilliqa:path',
      Polynetwork: 'https://explorer.poly.network:path',
      Osmosis: 'https://www.mintscan.io/osmosis:path',
      'Terra Classic': 'https://finder.terra.money/columbus-5:path',
      'Cosmos Hub': 'https://www.mintscan.io/cosmos:path',
      Juno: 'https://www.mintscan.io/juno:path',
      Evmos: 'https://www.mintscan.io/evmos:path',
      Axelar: 'https://axelarscan.io:path',
      Stride: 'https://www.mintscan.io/stride:path',
      Kujira: 'https://finder.kujira.app/kaiyo-1:path',
      Terra: 'https://finder.terra.money/mainnet:path',
      Comdex: 'https://www.mintscan.io/comdex:path',
      Stafihub: 'https://www.mintscan.io/stafi:path',
      'Persistence Core': 'https://www.mintscan.io/persistence:path',
      Stargaze: 'https://www.mintscan.io/stargaze:path',
      Canto: 'https://cosmos-explorers.neobase.one/canto:path',
      Sommelier: '',
      Decentr: '',
      'IRIS Hub': '',
      Noble: '',
      DYDX: '',
      Archway: '',
      Mantle: 'https://sepolia.mantlescan.xyz/:path',
      OP: 'https://sepolia-optimistic.etherscan.io/:path'
    },
  },
  CarbonHub: {
    BSCMigration: 'https://hub.carbon.network/migrate/bsc-migration',
  },
  StakingGuide: 'https://guide.dem.exchange/trade/fees/trading-fees',
  HydrogenRelays: {
    FlowId: 'https://hydrogen.carbon.network/relays/:flow_id',
    SearchTerm: 'https://hydrogen.carbon.network/relays?search_term={search_term}&net={network}',
  },
  BuyGlp: 'https://app.gmx.io/#/buy_glp',
  GetYieldUsd: 'https://app.sommelier.finance/strategies/Real-Yield-USD/manage',
  FluoDocs: {
    DevnetLaunch: 'https://dev-app.dem.exchange/pools',
    PerpPoolDetails: 'https://guide.dem.exchange/earn/perpetual-liquidity-pools/pool-details',
    PublicGuide: 'https://guide.dem.exchange/earn/perpetual-liquidity-pools',
    Airdrop: 'https://fluofinance.com/airdrop',
    FluoWebsite: 'https://fluofinance.com/',
    FluoTokens: 'https://docs.fluofinance.com/getting-started/what-is-fluo-token/what-is-esfluo-stfluo-and-vefluo#what-is-stfluo',
    Launch: 'https://docs.fluofinance.com/getting-started/launch-lockdrop-event',
    FluoVariations: 'https://docs.fluofinance.com/getting-started/what-is-fluo-token/what-is-esfluo-stfluo-and-vefluo',
    RewardsMultiplier: 'https://docs.fluofinance.com/getting-started/welcome-to-fluo/yield-boosting',
    VotingPower: 'https://docs.fluofinance.com/getting-started/what-is-fluo-token/what-is-esfluo-stfluo-and-vefluo',
  },
  FluoSocials: {
    Telegram: 'https://t.me/fluofinance',
    Discord: 'https://discord.com/invite/BFXbBNJ7MZ',
  },
  FeeDelegation: {
    Docs: 'https://guide.dem.exchange/trade/fees/fee-delegation',
  },
  EvmScan: 'https://evm-scan.carbon.network/',
}

export const ViewOnExplorerLink = {
  label: 'View on Explorer',
  target: '_blank',
  endIcon: ExternalLinkIcon,
}

export const ViewTransactionLink = {
  label: 'View Transaction',
  target: '_blank',
  endIcon: ExternalLinkIcon,
}

export function getNetworkQueryParam(net: CarbonSDK.Network) {
  if (net === CarbonSDK.Network.LocalHost) {
    return `net=${net.toLowerCase().replace(/host$/, '')}`
  }
  return `net=${net.toLowerCase().replace(/net$/, '')}`
}
export function getExplorerHost(net: CarbonSDK.Network) {
  if (net === CarbonSDK.Network.MainNet) {
    return 'https://scan.carbon.network'
  }
  return 'https://beta-scan.carbon.network'
}

export const getEvmHost = (net: CarbonSDK.Network) => {
  let host = ''
  switch (net) {
    case CarbonSDK.Network.MainNet:
      host = 'https://evm-scan.carbon.network'
      break
    case CarbonSDK.Network.TestNet:
      host = 'https://test-evm-scan.carbon.network'
      break
    default:
      host = 'https://dev-evm-scan.carbon.network'
      break
  }
  return host
}

export function getExplorerLink(net: CarbonSDK.Network) {
  return `${getExplorerHost(net)}?${getNetworkQueryParam(net)}`
}

export const getContractLink = (net: CarbonSDK.Network, contractAdd: string) => {
  const url = `${getEvmHost(net)}/address/${contractAdd}?${getNetworkQueryParam(net)}`
  return url
}

export function getEthExplorerLink(net: CarbonSDK.Network, txHash: string) {
  if (txHash) {
    switch (net) {
      case CarbonSDK.Network.DevNet: return `${StaticLinks.ExternalBlockchains.EthRopsten}${txHash}`
      default: return `${StaticLinks.ExternalBlockchains.Eth}${txHash}`
    }
  }
  return ''
}

export function getZilExplorerLink(net: CarbonSDK.Network, txHash: string = '') {
  if (txHash.length > 0) {
    const isDevnet = CarbonSDK.Network.DevNet === net
    return `${StaticLinks.ExternalBlockchains.Zil}${txHash}?network=${isDevnet ? 'testnet' : 'mainnet'}`
  }
  return ''
}

export function getNeoExplorerLink(txHash: string) {
  if (txHash) {
    let newTxHash = txHash
    if (txHash.substr(0, 2) !== '0x') {
      newTxHash = `0x${txHash}`
    }
    return `${StaticLinks.ExternalBlockchains.Neo}${newTxHash}`
  }
  return ''
}

export function getNeo3ExplorerLink(net: CarbonSDK.Network, txHash: string) {
  if (txHash) {
    let newTxHash = txHash
    if (txHash.substr(0, 2) !== '0x') {
      newTxHash = `0x${txHash}`
    }
    switch (net) {
      case CarbonSDK.Network.DevNet: return `${StaticLinks.ExternalBlockchains.Neo3Testnet}${newTxHash}`
      default: return `${StaticLinks.ExternalBlockchains.Neo3Mainnet}${newTxHash}`
    }
  }
  return ''
}

export function getOrderLink(orderId: string, net: CarbonSDK.Network) {
  return `${getExplorerHost(net)}/order/${orderId}?${getNetworkQueryParam(net)}`
}

export function getValidatorProfileLink(address: string, net: CarbonSDK.Network) {
  return `${getExplorerHost(net)}/validator/${address}?${getNetworkQueryParam(net)}`
}

export function getTokenLink(token: string, net: CarbonSDK.Network) {
  const tokenAddr = token.replace(/\//g, '%2F')
  return `${getExplorerHost(net)}/token/${tokenAddr}?${getNetworkQueryParam(net)}`
}

export function getMarketLink(market: string, net: CarbonSDK.Network) {
  const marketURI = encodeURIComponent(market)
  return `${getExplorerHost(net)}/market/${marketURI}?${getNetworkQueryParam(net)}`
}

export function getTxLink(txHash: string, net: CarbonSDK.Network) {
  return `${getExplorerHost(net)}/transaction/${txHash}?${getNetworkQueryParam(net)}`
}

export function getEvmTxLink(txHash: string, net: CarbonSDK.Network) {
  return `${getEvmHost(net)}/tx/${txHash}`
}

export function getPoolLink(net: CarbonSDK.Network, poolId?: number) {
  return `${getExplorerHost(net)}/pools${poolId ? `/${poolId}` : ''}?${getNetworkQueryParam(net)}`
}

export function switchWalletLink(transfer: Hydrogen.CrossChainTransfer, net: CarbonSDK.Network, toSelf: boolean) {
  let link = ''
  const walletAddress = toSelf ? transfer.from_address : transfer.to_address
  const walletAddressHash = toSelf ? transfer.from_address_hash : transfer.to_address
  const walletBlockchain = toSelf ? transfer.source_blockchain : transfer.destination_blockchain
  const paths = net === CarbonSDK.Network.MainNet ? StaticLinks.BlockChainPaths.mainnet : StaticLinks.BlockChainPaths.devnet
  switch (walletBlockchain) {
    case 'Neo':
      link = paths.Neo.replace(':path', `/address/${walletAddress}/page/1`)
      break
    case 'Neo3': /* FALLTHROUGH */
    case 'Arbitrum': /* FALLTHROUGH */
    case 'Polygon': /* FALLTHROUGH */
    case 'OKC':
      link = paths[walletBlockchain as keyof typeof paths].replace(':path', `/address/${walletAddress}`)
      break
    case 'Ethereum': /* FALLTHROUGH */
    case 'Binance Smart Chain': /* FALLTHROUGH */
    case 'BSC testnet': /* FALLTHROUGH */
    case 'Mantle': /* FALLTHROUGH */
    case 'OP': /* FALLTHROUGH */
    case 'Zilliqa':
      link = paths[walletBlockchain as keyof typeof paths].replace(':path', `/address/${walletAddressHash}`)
      break
    case 'Osmosis': /* FALLTHROUGH */
    case 'Cosmos Hub': /* FALLTHROUGH */
    case 'Juno': /* FALLTHROUGH */
    case 'Evmos': /* FALLTHROUGH */
    case 'Axelar': /* FALLTHROUGH */
    case 'Stride': /* FALLTHROUGH */
    case 'Kujira': /* FALLTHROUGH */
    case 'Terra': /* FALLTHROUGH */
    case 'Comdex': /* FALLTHROUGH */
    case 'Stafihub': /* FALLTHROUGH */
    case 'Persistence Core': /* FALLTHROUGH */
    case 'Stargaze': /* FALLTHROUGH */
    case 'Canto': /* FALLTHROUGH */
    case 'Sommelier': /* FALLTHROUGH */
    case 'Terra Classic': /* FALLTHROUGH */
    case 'Noble': /* FALLTHROUGH */
    case 'DYDX': /* FALLTHROUGH */
    case 'Archway':
      link = paths[walletBlockchain as keyof typeof paths].replace(':path', `/account/${walletAddress}`)
      break
    case 'Decentr':
      link = paths[walletBlockchain as keyof typeof paths].replace(':path', `/accounts/${walletAddress}`)
      break
    // TODO: Check hydrogen id and update this function
    case 'IRIS Hub':
      link = paths['IRIS Hub'].replace(':path', `/accounts/${walletAddress}`)
      break
    case 'Switcheo': /* FALLTHROUGH */
    case 'Carbon':
      link = `https://scan.carbon.network/account/${walletAddress}`
      break
    case 'Tradehub':
      link = `https://legacy-scan.carbon.network/account/${walletAddress}`
      break
    default:
      break
  }
  return link
}

export function getWalletAddressLink(walletBlockchain: string, net: CarbonSDK.Network, walletAddress: string) {
  let link = ''
  const paths = net === CarbonSDK.Network.MainNet ? StaticLinks.BlockChainPaths.mainnet : StaticLinks.BlockChainPaths.devnet
  switch (walletBlockchain) {
    case 'Neo':
      link = paths.Neo.replace(':path', `/address/${walletAddress}/page/1`)
      break
    case 'Neo3': /* FALLTHROUGH */
    case 'Arbitrum': /* FALLTHROUGH */
    case 'Polygon': /* FALLTHROUGH */
    case 'OKC':
      link = paths[walletBlockchain as keyof typeof paths].replace(':path', `/address/${walletAddress}`)
      break
    case 'Ethereum': /* FALLTHROUGH */
    case 'Binance Smart Chain': /* FALLTHROUGH */
    case 'BSC testnet': /* FALLTHROUGH */
    case 'Mantle': /* FALLTHROUGH */
    case 'OP': /* FALLTHROUGH */
    case 'Zilliqa':
      link = paths[walletBlockchain as keyof typeof paths].replace(':path', `/address/${walletAddress}`)
      break
    case 'Osmosis': /* FALLTHROUGH */
    case 'Cosmos Hub': /* FALLTHROUGH */
    case 'Juno': /* FALLTHROUGH */
    case 'Evmos': /* FALLTHROUGH */
    case 'Axelar': /* FALLTHROUGH */
    case 'Stride': /* FALLTHROUGH */
    case 'Kujira': /* FALLTHROUGH */
    case 'Terra': /* FALLTHROUGH */
    case 'Comdex': /* FALLTHROUGH */
    case 'Stafihub': /* FALLTHROUGH */
    case 'Persistence Core': /* FALLTHROUGH */
    case 'Stargaze': /* FALLTHROUGH */
    case 'Canto': /* FALLTHROUGH */
    case 'Sommelier': /* FALLTHROUGH */
    case 'Terra Classic': /* FALLTHROUGH */
    case 'Noble': /* FALLTHROUGH */
    case 'DYDX': /* FALLTHROUGH */
    case 'Archway':
      link = paths[walletBlockchain as keyof typeof paths].replace(':path', `/account/${walletAddress}`)
      break
    case 'Decentr':
      link = paths[walletBlockchain as keyof typeof paths].replace(':path', `/accounts/${walletAddress}`)
      break
    // TODO: Check hydrogen id and update this function
    case 'IRIS Hub':
      link = paths['IRIS Hub'].replace(':path', `/accounts/${walletAddress}`)
      break
    case 'Switcheo': /* FALLTHROUGH */
    case 'Carbon':
      link = `https://scan.carbon.network/account/${walletAddress}`
      break
    case 'Carbon EVM':
      link = `${StaticLinks.EvmScan}/address/${walletAddress}`
      break
    case 'Tradehub':
      link = `https://legacy-scan.carbon.network/account/${walletAddress}`
      break
    default:
      break
  }
  return link
}

export function switchHashLink(transfer: Hydrogen.CrossChainTransfer, net: CarbonSDK.Network, isSource: boolean) {
  const txHash = isSource ? transfer.relay?.source_tx_hash : transfer.relay?.destination_tx_hash
  const txBlockchain = isSource ? transfer.source_blockchain : transfer.destination_blockchain
  return getTxHashLink(txBlockchain, net, txHash)
}

export function hydrogenRelaysLink(crossChainFlowId: string, net?: CarbonSDK.Network) {
  let link = StaticLinks.HydrogenRelays.FlowId.replace(':flow_id', `${crossChainFlowId}`)
  if (net && (net !== CarbonSDK.Network.MainNet)) {
    link = link + `?net=${net}`
  }
  return link
}

export function hydrogenPendingTxLink(txHash: string, net: CarbonSDK.Network = CarbonSDK.Network.MainNet) {
  const link = StaticLinks.HydrogenRelays.SearchTerm.replace('{search_term}', `${txHash}`).replace('{network}', `${net}`)
  return link
}

export function getTxHashLink(blockchain: BlockchainV2 | null, net: CarbonSDK.Network, txHash: string | undefined) {
  const paths = net === CarbonSDK.Network.MainNet ? StaticLinks.BlockChainPaths.mainnet : StaticLinks.BlockChainPaths.devnet
  const txHashDisplay = stripHexPrefix(txHash ?? '')
  let link = ''
  switch (blockchain) {
    case 'Neo': /* FALLTHROUGH */
    case 'Neo3':
      link = paths[blockchain as keyof typeof paths].replace(':path', `/transaction/${txHash}`)
      break
    case 'Ethereum': /* FALLTHROUGH */
    case 'Binance Smart Chain': /* FALLTHROUGH */
    case 'BSC testnet': /* FALLTHROUGH */
    case 'Arbitrum': /* FALLTHROUGH */
    case 'Zilliqa': /* FALLTHROUGH */
    case 'Canto': /* FALLTHROUGH */
    case 'Polygon': /* FALLTHROUGH */
    case 'Mantle': /* FALLTHROUGH */
    case 'OP': /* FALLTHROUGH */
    case 'OKC':
      link = paths[blockchain as keyof typeof paths].replace(':path', `/tx/${txHash}`)
      break
    case 'Osmosis': /* FALLTHROUGH */
    case 'Cosmos Hub': /* FALLTHROUGH */
    case 'Juno': /* FALLTHROUGH */
    case 'Evmos': /* FALLTHROUGH */
    case 'Stride': /* FALLTHROUGH */
    case 'Stafihub': /* FALLTHROUGH */
    case 'Comdex': /* FALLTHROUGH */
    case 'Persistence Core': /* FALLTHROUGH */
    case 'Stargaze': /* FALLTHROUGH */
    case 'Sommelier': /* FALLTHROUGH */
    case 'Noble': /* FALLTHROUGH */
    case 'DYDX': /* FALLTHROUGH */
    case 'Archway':
      link = paths[blockchain as keyof typeof paths].replace(':path', `/txs/${txHashDisplay}`)
      break
    case 'Axelar': /* FALLTHROUGH */
    case 'Kujira': /* FALLTHROUGH */
    case 'Terra Classic': /* FALLTHROUGH */
    case 'Terra':
      link = paths[blockchain as keyof typeof paths].replace(':path', `/tx/${txHashDisplay}`)
      break
    case 'Decentr':
      link = paths[blockchain as keyof typeof paths].replace(':path', `/transactions/${txHashDisplay}`)
      break
    // TODO: Check hydrogen id and update this function
    case 'IRIS Hub':
      link = paths['IRIS Hub'].replace(':path', `/txs/${txHashDisplay}`)
      break
    case 'Switcheo': /* FALLTHROUGH */
    case 'Carbon':
      link = `https://scan.carbon.network/transaction/${txHashDisplay}`
      break
    case 'Tradehub':
      link = `https://legacy-scan.carbon.network/transaction/${txHashDisplay}`
      break
    default:
      break
  }
  return link
}

const tokensPathOverride: Partial<{ [key in BlockchainV2]: string }> = {
  Neo: '/assets/page/1',
  Osmosis: '/assets',
  OKC: '/token-list',
  'Terra Classic': '', // TODO: find out and fill in path (if override is required)
}

export function switchTokensLink(blockchain: BlockchainV2, net: CarbonSDK.Network) {
  const paths = net === CarbonSDK.Network.MainNet ? StaticLinks.BlockChainPaths.mainnet : StaticLinks.BlockChainPaths.devnet
  const domainPath = (paths as SimpleMap<string>)[blockchain]
  if (!domainPath) return ''
  const indivPath = tokensPathOverride[blockchain] ?? '/tokens'
  return domainPath.replace(':path', indivPath)
}

export const getBridgeLink = (blockchain: BlockchainV2, address: string, net: CarbonSDK.Network) => {
  const paths = net === CarbonSDK.Network.MainNet ? StaticLinks.BlockChainPaths.mainnet : StaticLinks.BlockChainPaths.devnet
  switch (blockchain) {
    case 'Ethereum': {
      return paths[blockchain as keyof typeof paths].replace(':path', `/address/0x${address}`)
    }
    case 'Binance Smart Chain': {
      return paths[blockchain as keyof typeof paths].replace(':path', `/address/0x${address}`)
    }
    case 'Neo': {
      return paths[blockchain as keyof typeof paths].replace(':path', `/address/${NEOAddress.encode(address)}/page/1`)
    }
    case 'Neo3': {
      return paths[blockchain as keyof typeof paths].replace(':path', `/address/${N3Address.encode(address)}`)
    }
    case 'Zilliqa': {
      return paths[blockchain as keyof typeof paths].replace(':path', `/address/${toBech32Address(address)}`)
    }
    default:
      return ''
  }
}

export const getImportTokenLink = (blockchain: BlockchainV2, address: string, net: CarbonSDK.Network) => {
  const paths = net === CarbonSDK.Network.MainNet ? StaticLinks.BlockChainPaths.mainnet : StaticLinks.BlockChainPaths.devnet
  switch (blockchain) {
    case 'Ethereum': {
      return paths.Ethereum.replace(':path', `/token/0x${address}`)
    }
    case 'Binance Smart Chain': {
      return paths['Binance Smart Chain'].replace(':path', `/token/0x${address}`)
    }
    case 'Neo': {
      return paths.Neo.replace(':path', `/token/${reverseStr(address, 2)}/page/1`)
    }
    default:
      return ''
  }
}

export function getAddressLink(address: string | null, net: CarbonSDK.Network, paramsStr: string = '') {
  const link = `${getExplorerHost(net)}/account/${address}?${getNetworkQueryParam(net)}`
  if (paramsStr.length > 0) {
    return `${link}&${paramsStr}`
  }
  return link
}

export const goToExternalLink = (url: string) => {
  window.open(url, '_blank')
}

export function getCdpLink(net: CarbonSDK.Network, denom?: string) {
  if (!denom) {
    return `${getExplorerHost(net)}/cdp?${getNetworkQueryParam(net)}`
  }
  return `${getExplorerHost(net)}/cdp/${encodeURIComponent(denom)}?${getNetworkQueryParam(net)}`
}

export const getDemexWebappDomain = (useHttp: boolean = true) => {
  if (IS_ENV_STAGING) {
    return `${useHttp ? 'https://' : ''}beta-app.dem.exchange`
  }
  if (IS_ENV_DEVELOP) {
    return `${useHttp ? 'https://' : ''}dev-app.dem.exchange`
  }
  return `${useHttp ? 'https://' : ''}app.dem.exchange`
}

export function getGovernanceAddressLink(net: CarbonSDK.Network, paramsStr: string = '') {
  const link = `${getExplorerHost(net)}/governance?${getNetworkQueryParam(net)}`
  if (paramsStr.length > 0) {
    return `${link}&${paramsStr}`
  }
  return link
}

export function getMarketFuturesLink(net: CarbonSDK.Network) {
  return `${getExplorerHost(net)}/market/futures?${getNetworkQueryParam(net)}`
}

export const urlRegex = /http(s?):\/\/([a-z0-9\.\/\:\-\_?=\[\]&]{1,})(\/?)/gi // eslint-disable-line no-useless-escape
