import { CarbonSDK } from 'carbon-js-sdk'
import { Bridge } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/bridge'
import { Token } from 'carbon-js-sdk/lib/codec/Switcheo/carbon/coin/token'
import { DenomTrace } from 'carbon-js-sdk/lib/codec/ibc/applications/transfer/v1/transfer'
import { ExtendedChainInfo } from 'carbon-js-sdk/lib/constant/ibc' // eslint-disable-line import/no-unresolved
import { StoredMnemonicInfo } from 'carbon-js-sdk/lib/provider/metamask/MetaMask' // eslint-disable-line import/no-unresolved
import { action } from 'typesafe-actions'

import { OracleInfo } from 'js/constants/chart'
import { FeeDropDownItem } from 'js/constants/fees'
import { NodeFormState, RatingLatencyObj } from 'js/utils/nodes'
import { SimpleMap } from 'js/utils/types'

import ReferralClient from './helpers/ReferralClient'
import { AppActionTypes, AdditionalIbcTokenList, CustomNodeItem, DemexConfig, GroupTokenDetails, MinGasPricesMap, PWABeforeInstallPromptEvent, Page, SuccessBlacklistProps, ThirdPartyAPIStatuses, TransactionStatus, EthersRPCProviders } from './types'

export const setSubPage = (subPage: Page) => action(
  AppActionTypes.SET_SUB_PAGE,
  subPage,
)

export const setAppVersion = (appVersion: string) => action(
  AppActionTypes.SET_APP_VERSION,
  appVersion,
)

export const initChainInfoMap = () => action(
  AppActionTypes.INIT_CHAININFO_MAP,
)

export const setWalletToggle = (walletToggle: boolean) => action(
  AppActionTypes.SET_WALLET_TOGGLE,
  walletToggle,
)

export const setThemeType = (themeType: string) => action(
  AppActionTypes.SET_THEME_TYPE,
  themeType,
)

export const setWindowActive = (active: boolean) => action(
  AppActionTypes.SET_WINDOW_ACTIVE,
  active,
)

export const setNet = (net: string) => action(
  AppActionTypes.SET_NET,
  net,
)

export const networkChanged = (net: CarbonSDK.Network) => action(
  AppActionTypes.NETWORK_CHANGED,
  net,
)

export const setCarbonSDK = (sdk: CarbonSDK) => action(
  AppActionTypes.SET_CARBON_SDK,
  sdk,
)

export const setTokens = (tokens: Token[]) => action(
  AppActionTypes.SET_TOKENS,
  tokens,
)

export const setNewTokenDenoms = (newTokenDenoms: Set<string>) => action(
  AppActionTypes.SET_NEW_TOKEN_DENOMS,
  newTokenDenoms,
)

export const updateBlockHeight = (height: number) => action(
  AppActionTypes.UPDATE_BLOCK_HEIGHT,
  height,
)

export const resetBlockHeight = () => action(
  AppActionTypes.RESET_BLOCK_HEIGHT,
)

export const updateBlocksMoving = (status: boolean) => action(
  AppActionTypes.UPDATE_BLOCKS_MOVING,
  status,
)


export const reloadTokens = () => action(
  AppActionTypes.FETCH_TOKENS,
)

export const requestSign = () => action(
  AppActionTypes.REQUEST_SIGN,
)

export const finishSign = () => action(
  AppActionTypes.FINISH_SIGN,
)

export const setShowEditLeverageDialog = (show: boolean) => action(
  AppActionTypes.SET_SHOW_EDIT_LEVERAGE_DIALOG,
  show,
)

export const setHideConvertDialog = (address: string) => action(
  AppActionTypes.SET_HIDE_CONVERT_DIALOG,
  address,
)

export const setMobileMenuOpen = () => action(
  AppActionTypes.SET_MOBILE_MENU_OPEN,
)

export const setMobileMenuClose = () => action(
  AppActionTypes.SET_MOBILE_MENU_CLOSE,
)

export const setMobileMoreMenuOpen = () => action(
  AppActionTypes.SET_MOBILE_MORE_MENU_OPEN,
)

export const setMobileMoreMenuClose = () => action(
  AppActionTypes.SET_MOBILE_MORE_MENU_CLOSE,
)

export const setMobileNotificationMenuOpen = () => action(
  AppActionTypes.SET_MOBILE_NOTIFICATION_MENU_OPEN,
)

export const setMobileNotificationMenuClose = () => action(
  AppActionTypes.SET_MOBILE_NOTIFICATION_MENU_CLOSE,
)

export const setAllMenusClose = () => action(
  AppActionTypes.SET_ALL_MENUS_CLOSE,
)

export const setWalletDropdownOpen = () => action(
  AppActionTypes.SET_WALLET_DROPDOWN_OPEN,
)

export const setFeeToggleOpen = () => action(
  AppActionTypes.SET_FEE_TOGGLE_OPEN,
)

export const setFeeToggleClose = () => action(
  AppActionTypes.SET_FEE_TOGGLE_CLOSE,
)

export const clearDropdowns = () => action(
  AppActionTypes.CLEAR_DROPDOWNS,
)

export const clearCachedWallet = () => action(AppActionTypes.CLEAR_CONNECTED_WALLET)

export const updateSuccessBlacklist = (txBlacklist: SuccessBlacklistProps) => action(
  AppActionTypes.SET_SUCCESS_MSG_BLACKLIST,
  txBlacklist,
)

export const updateHideSuccess = (hideSuccess: SuccessBlacklistProps) => action(
  AppActionTypes.SET_HIDE_SUCCESS_MSGS,
  hideSuccess,
)

export const setDisplayMenuOpen = () => action(
  AppActionTypes.SET_DISPLAY_MENU_OPEN,
)

export const setDisplayMenuClose = () => action(
  AppActionTypes.SET_DISPLAY_MENU_CLOSE,
)

export const setHelpMenuOpen = () => action(
  AppActionTypes.SET_HELP_MENU_OPEN,
)

export const setHelpMenuClose = () => action(
  AppActionTypes.SET_HELP_MENU_CLOSE,
)

export const setNodeMenuOpen = () => action(
  AppActionTypes.SET_NODE_MENU_OPEN,
)

export const setNodeMenuClose = () => action(
  AppActionTypes.SET_NODE_MENU_CLOSE,
)

export const setNodes = (nodes: any[]) => action(
  AppActionTypes.SET_NODES,
  nodes,
)

export const showNodeInfoForm = () => action(
  AppActionTypes.SHOW_NODE_INFO_FORM,
)

export const hideNodeInfoForm = () => action(
  AppActionTypes.HIDE_NODE_INFO_FORM,
)

export const setCustomNodes = (nodes: CustomNodeItem[]) => action(
  AppActionTypes.SET_CUSTOM_NODES,
  nodes,
)

export const setSelectedNodes = (nodes: SimpleMap<any>) => action(
  AppActionTypes.SET_SELECTED_NODES,
  nodes,
)

export const setAutoSelectNode = (autoSelect: boolean) => action(
  AppActionTypes.SET_AUTO_SELECT_NODE,
  autoSelect,
)

export const setFormNode = (node: NodeFormState | undefined) => action(
  AppActionTypes.SET_FORM_NODE,
  node,
)

export const setInternetConnected = (internetConnected: boolean) => action(
  AppActionTypes.SET_INTERNET_CONNECTED,
  internetConnected,
)

export const setAllBridges = (bridges: Bridge[]) => action(
  AppActionTypes.SET_ALL_BRIDGES,
  bridges,
)

export const setRatingLatency = (rating: RatingLatencyObj) => action(
  AppActionTypes.SET_RATING_LATENCY,
  rating,
)

export const setMinGasPrices = (gasPrices: MinGasPricesMap) => action(
  AppActionTypes.SET_MIN_GAS_PRICES,
  gasPrices,
)

export const setCachedWalletLoading = (loading: boolean) => action(
  AppActionTypes.SET_CACHED_WALLET_LOADING,
)

export const setDemexConfig = (demexConfig: DemexConfig) => action(
  AppActionTypes.SET_DEMEX_CONFIG,
  demexConfig,
)

export const setShowMobilePromo = (show: boolean) => action(
  AppActionTypes.SET_SHOW_MOBILE_PROMO,
  show,
)

export const setGroupTokensMap = (groupTokensMap: SimpleMap<GroupTokenDetails>) => action(
  AppActionTypes.SET_GROUP_TOKENS_MAP,
  groupTokensMap,
)

export const setChainInfoMap = (chainInfoMap: SimpleMap<ExtendedChainInfo>) => action(
  AppActionTypes.SET_CHAIN_INFO_MAP,
  chainInfoMap,
)

export const queryChainInfoMap = () => action(AppActionTypes.QUERY_CHAIN_INFO_MAP)

export const setErrorChainInfoMap = (errorChainInfoMap: string) => action(
  AppActionTypes.SET_ERROR_CHAIN_INFO_MAP,
  errorChainInfoMap,
)

export const setDenomTraces = (denomTraces: SimpleMap<DenomTrace>) => action(
  AppActionTypes.SET_IBC_DENOM_TRACES,
  denomTraces,
)

export const setAdditionalIbcTokens = (additionalIbcTokens: AdditionalIbcTokenList) => action(
  AppActionTypes.SET_ADDITIONAL_IBC_TOKENS,
  additionalIbcTokens,
)

export const setConfettiState = (confettiState: boolean) => action(
  AppActionTypes.SET_CONFETTI_STATE,
  confettiState,
)

export const setDisableConfetti = (disableConfetti: boolean) => action(
  AppActionTypes.SET_DISABLE_CONFETTI,
  disableConfetti,
)

export const setLoggingIn = (loggingIn: boolean) => action(
  AppActionTypes.SET_LOGGING_IN,
  loggingIn,
)

export const setPromoRegistered = (promoRegistered: boolean) => action(
  AppActionTypes.SET_PROMO_REGISTERED,
  promoRegistered,
)

export const setLegacyAccounts = (legacyAccounts: StoredMnemonicInfo[]) => action(
  AppActionTypes.SET_LEGACY_ACCOUNTS,
  legacyAccounts,
)

export const setLegacyLoggedIn = (legacyLoggedIn: boolean) => action(
  AppActionTypes.SET_LEGACY_LOGIN,
  legacyLoggedIn,
)

export const setShowCreateAccountDialog = (show: boolean) => action(
  AppActionTypes.SET_SHOW_CREATE_ACCOUNT_DIALOG,
  show,
)

export const setActivateAccountTxStatus = (status: TransactionStatus) => action(
  AppActionTypes.SET_ACTIVATE_ACCOUNT_TX_STATUS,
  status,
)

export const setSubsequentTxStatus = (status: TransactionStatus) => action(
  AppActionTypes.SET_SUBSEQUENT_TX_STATUS,
  status,
)

export const resetActivateAccountStatuses = () => action(
  AppActionTypes.RESET_ACTIVATE_ACCOUNT_STATUSES,
)

export const setThirdPartyAPIStatuses = (statuses: ThirdPartyAPIStatuses) => action(
  AppActionTypes.SET_THIRD_PARTY_API_STATUSES,
  statuses,
)

export const setReferralClient = (client: ReferralClient | undefined) => action(
  AppActionTypes.SET_REFERRAL_CLIENT,
  client,
)

export const setShowFeedbackBanner = (show: boolean) => action(
  AppActionTypes.SET_FEEDBACK_BANNER,
  show,
)

export const setPwaInstallPrompt = (installPrompt: PWABeforeInstallPromptEvent | null) => action(
  AppActionTypes.SET_PWA_INSTALL_PROMPT,
  installPrompt,
)

export const openPWAInstallDialog = (show: boolean) => action(
  AppActionTypes.OPEN_PWA_INSTALL_DIALOG,
  show,
)

export const setShowPromoBanner = (show: boolean) => action(
  AppActionTypes.SET_PROMO_BANNER,
  show,
)

export const setOracleInfo = (oracleInfo: OracleInfo[]) => action(
  AppActionTypes.SET_ORACLE_INFO,
  oracleInfo,
)

export const setIsPwaUpdated = (show: boolean) => action(
  AppActionTypes.SET_IS_PWA_UPDATED,
  show,
)

export const setQueuePwaUpdateToast = (show: boolean) => action(
  AppActionTypes.QUEUE_PWA_UPDATE_TOAST,
  show,
)

export const addHiddenBanner = (bannerId: string) => action(
  AppActionTypes.ADD_HIDDEN_BANNER,
  bannerId
)

export const subscribeToPushNotifications = (permission: NotificationPermission) => action(
  AppActionTypes.SUBSCRIBE_TO_PUSH_NOTIFICATIONS,
  permission,
)

export const setCurrentFeeSettingsDropdown = (dropdownItem: FeeDropDownItem) => action(
  AppActionTypes.SET_FEE_SETTINGS_DROPDOWN,
  dropdownItem
)

export const setHideTypeformWidget = (address: string) => action(
  AppActionTypes.SET_HIDE_TYPEFORM_WIDGET,
  address
)

export const setHiddenTypeformWidgetsArray = (hiddenWidgets: string[]) => action(
  AppActionTypes.SET_HIDDEN_TYPEFORM_WIDGETS_ARRAY,
  hiddenWidgets
)

export const setEthersProviders = (providers: EthersRPCProviders) => action(
  AppActionTypes.SET_ETHERS_RPC_PROVIDERS,
  providers
)